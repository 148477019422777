<template>
    <v-card class="elevation-4" height="100%">
        <v-card-text class="pa-0">
            <v-list-item three-line class="text-top">
                <v-list-item-avatar size="60" tile class="secondary">
                    <!-- <v-img src="@/assets/images/avatars/profil.svg"></v-img> -->
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title><strong>{{ value.title }}</strong></v-list-item-title>
                    <v-list-item-subtitle>{{ value.company.name }}</v-list-item-subtitle>
                    <v-list-item-subtitle>
                    <div class="d-flex flex-row w-auto">
                        <v-icon size="1.2em" class="mr-2">{{ icons.mdiMapMarkerOutline }}</v-icon>
                        <span class="mr-2">{{ value.location}}</span>
                        <!-- <span>{{ value.location + ', ' + value.country.name }}</span> -->
                        <v-img min-width="1.6em" max-width="1.6em" width="1.6em" height="1em" aspect-ratio="1" :src="require('@/assets/images/flags/' + value.country.iso + '.svg')" :lazy-src="'@/assets/images/flags/' + value.country.iso + '.svg'">
                            <template v-slot:placeholder>
                                <div class="fill-height ma-0" align="center" justify="center">
                                    <v-skeleton-loader v-bind="attrs" type="image" height="1em" width="1.5em"
                                        class="mx-auto rounded-pill"></v-skeleton-loader>
                                </div>
                            </template>
                        </v-img></div>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                        <span class="mr-4"> {{ value.periodicity }}</span>
                        <span class="error--text"> Deadline: {{ value.dateline }}</span>
                    </v-list-item-subtitle>
                    <v-card-text class="px-0 h-job-content" height="1em" v-html="value.description"></v-card-text>
                </v-list-item-content>
            </v-list-item>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small outlined color="secondary" class="subsecondary" elevation="0" @click="viewjobdetails">
                <v-icon small class="mr-2">{{ icons.mdiOpenInNew }}</v-icon>
                Details
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mdiMapMarkerOutline, mdiOpenInNew } from '@mdi/js'
import { VueEditor } from "vue2-editor";
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { Utils } from '@/plugins/Utils';

export default {
    props: {
        value: {
            type: Object,
            default: () => { }
        },
    },
    data() {
        return {
            dialgtimer: 5000,
            attrs: {}
        }
    },

    methods: {
        viewjobdetails() {
            if (!this.$store.getters.isLoggedIn) {
                this.$router.push({ name: 'login', params: {} })
            }
            else {
                this.$router.push({ name: 'vacancies.details', params: { offer_id: this.value.id, offer_title: Utils.replaceAll(this.value.title, ' ', '-') } })

                // if (this.$store.getters.isSubscribed === true) {
                //     if (this.$store.getters.getSubscribtionLevel >= 1) {
                //         this.$router.push({name: 'vacancies.details', params: { jobcompany: this.value.company, offer_id: this.value.title.replace("-", ' ') }})
                //     }
                // }
                // else {
                //     this.$fire({
                //         title: "Required subscription",
                //         text: "You must subscribe to an offer to access more features.",
                //         timer: this.dialgtimer
                //     }).then(() => {
                //         this.$router.push({ name: 'pricing', params: {} })
                //     })
                // }}
            }
        },
    },
    beforeMount() {
    },
    setup() {
        const icons = { mdiMapMarkerOutline, mdiOpenInNew }

        return {
            icons
        }
    },

}
</script>
<style lang="scss" scoped>
@import '@/styles/styles.scss';

.h-job-content {
    height: 3em !important;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>